import Image from "next/image";

import { Button } from "../Utils/Button";
import { Container } from "../Utils/Container";
import logoLaravel from "../../images/logos/laravel.svg";
import logoMirage from "../../images/logos/mirage.svg";
import logoStatamic from "../../images/logos/statamic.svg";
import logoStaticKit from "../../images/logos/statickit.svg";
import logoTransistor from "../../images/logos/transistor.svg";
import logoTuple from "../../images/logos/tuple.svg";

export function Hero() {
  return (
    <Container className="pt-20 pb-16 text-center lg:pt-32">
      <h1 className="mx-auto max-w-4xl font-display text-5xl text-slate-900">
        Test the compatibility of your devices &amp; software with RTSP
        streaming
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
        Our state-of-the-art servers ensure reliable, stable streaming, so you
        can focus on testing and optimizing your setup. Our user-friendly
        platform makes it easy to manage your streams and access them from any
        device.
      </p>
      <div className="mt-10 flex justify-center gap-x-6">
        <Button href="/auth/login">Get started for free</Button>
        {/*
        <Button variant="outline">
          <svg
            aria-hidden="true"
            className="h-3 w-3 flex-none fill-blue-600 group-active:fill-current"
          >
            <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
          </svg>
          <span className="ml-3">Watch video</span>
        </Button>
      */}
      </div>
      <div className="mt-36 lg:mt-44">
        {/*
        <p className="font-display text-base text-slate-900">
          Trusted by these six companies so far
        </p>
        <ul className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0">
          {[
            [
              { name: "Transistor", logo: logoTransistor },
              { name: "Tuple", logo: logoTuple },
              { name: "StaticKit", logo: logoStaticKit },
            ],
            [
              { name: "Mirage", logo: logoMirage },
              { name: "Laravel", logo: logoLaravel },
              { name: "Statamic", logo: logoStatamic },
            ],
          ].map((group, groupIndex) => (
            <li key={groupIndex}>
              <ul className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
                {group.map((company) => (
                  <li key={company.name} className="flex">
                    <Image
                      src={company.logo}
                      alt={company.name}
                      layout="fixed"
                      unoptimized
                    />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        */}
      </div>
    </Container>
  );
}
