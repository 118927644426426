import Image from "next/image";

import { Container } from "@components/Utils/Container";
import backgroundImage from "@images/background-faqs.jpg";

const faqs = [
  [
    {
      question: "Is this service really free?",
      answer: "Yes! It's free to get started. We may add paid-tiers in future.",
    },
    {
      question: "How many test streams can I create?",
      answer:
        "Currently, we provide two test streams for free. We may add ability to create more streams in future.",
    },
    {
      question: "How do I use these test streams?",
      answer:
        "You can use these test streams to stream a test video on the RTSP protocol which can be used to test your software applications.",
    },
  ],
  [
    {
      question: "How do I get started?",
      answer:
        "Once you sign up, you will be able to access RTSP streams on the dashboard and use them in your applications.",
    },
    {
      question: "Can I monitor my usage?",
      answer:
        "On the dashboard, we currently provide active sessions and bytes sent for each stream while it's being consumed. We may add more metrics in future.",
    },
    {
      question: "How do I ensure that the streams work?",
      answer:
        "You can use off-the-shelf tools like VLC Player or GStreamer or FFMpeg to stream the test video on the RTSP protocol.",
    },
  ],
  [
    {
      question: "Can you add more features that I need?",
      answer:
        "Yes! We would love to hear from you. Please send us an email on dave@rtsp.stream.",
    },
    {
      question: "Can this tool stream from RTSP Cameras to HLS?",
      answer:
        "We currently don't support this feature but if there's enough demand, we can add it in future.",
    },
    {
      question: "Are the streams password protected?",
      answer:
        "The streamKey in the URL acts as your password. We authenticate the streamKey in the URL and allow access to the stream.",
    },
  ],
];

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <div className="absolute top-0 left-1/2 -translate-x-[30%] -translate-y-[25%]">
        <Image
          src={backgroundImage}
          alt=""
          width={1558}
          height={946}
          layout="fixed"
          unoptimized
        />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can`t find what you`re looking for, please send an email to dave@rtsp.stream.
          </p>
        </div>
        <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
