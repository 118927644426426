import { useEffect, useState } from "react";
import Image from "next/image";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

import { Container } from "@components/Utils/Container";
import backgroundImage from "@images/background-features.jpg";

export function PrimaryFeatures() {
  return (
    <section
      id="features"
      aria-label="Features provided by the product"
      className="relative overflow-hidden bg-indigo-800 pt-10 pb-28 sm:py-12"
    >
      
      <Container className="relative">
        <h1 className="text-center font-display text-2xl text-gray-400">
          RTSP.Stream currently provides two RTSP sources that can be used for
          debugging and testing your video software applications
        </h1>
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none mt-8">
          <h2 className="font-display text-2xl text-white mb-4">
            SMPTE Color Bars With Timestamp
          </h2>
          <div className="xl:flex xl:flex-row-reverse">
            <video autoPlay muted loop className="p-2 bg-gray-800 rounded-sm shadow-md mx-auto">
              <source src="/videos/pattern.mp4" type="video/mp4" />
              {/* <source src="/videos/rtsp-stream-tester.webm" type="video/webm" /> */}
            </video>
            <div className="mt-6 text-lg text-gray-200 p-4 text-justify">
              SMPTE color bars are a television test pattern used where the NTSC
              video standard is utilized, including countries in North America.
              The Society of Motion Picture and Television Engineers (SMPTE)
              refers to the pattern as Engineering Guideline (EG) 1-1990. Its
              components are a known standard, and created by test pattern
              generators. Comparing it as received to the known standard gives
              video engineers an indication of how an NTSC video signal has been
              altered by recording or transmission and what adjustments must be
              made to bring it back to specification. It is also used for
              setting a television monitor or receiver to reproduce NTSC
              chrominance and luminance information correctly.
            </div>
          </div>
        </div>
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none mt-8">
          <h2 className="font-display text-2xl text-white">
            Big Buck Bunny Movie
          </h2>
          <div className="xl:flex">
            <video autoPlay muted loop className="p-2 bg-gray-800 rounded-sm shadow-md mx-auto">
              <source src="/videos/movie.mp4" type="video/mp4" />
              {/* <source src="/videos/rtsp-stream-tester.webm" type="video/webm" /> */}
            </video>

            <div className="mt-6 text-lg text-gray-200 p-4 text-justify">
              Big Buck Bunny is a 2008 short computer-animated comedy film
              featuring animals of the forest, made by the Blender Institute,
              part of the Blender Foundation. The film was made using Blender, a
              free and open-source software application for 3D computer modeling
              and animation developed by the same foundation. It was released as
              an open-source film under the Creative Commons Attribution 2.5
              license.
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
