import FeedbackBanner from "@components/Footers/Feedback";
import { Footer } from "@components/Footers/Footer";
import { Header } from "@components/Headers/Header";
import { CallToAction } from "@components/Sections/CallToAction";
import { Faqs } from "@components/Sections/Faqs";
import { Hero } from "@components/Sections/Hero";
import Pricing from "@components/Sections/Pricing";
import { PrimaryFeatures } from "@components/Sections/PrimaryFeatures";
import Head from "next/head";
import dynamic from "next/dynamic";
const CrispWithNoSSR = dynamic(() => import("@components/Footers/Crisp"), {
  ssr: false,
});

export default function Home() {
  const title = `${process.env.NEXT_PUBLIC_PROJECT_NAME} - Test Your Video Camera Software, DVR, NVR, Surveillance applications`;
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="description"
          content="Test RTSP Streams to test your Video Camera Software, DVR, NVR, Surveillance applications"
        />
      </Head>
      <Header />
      <main>
        <Hero />
        <PrimaryFeatures />
        <Faqs />
        <CallToAction />
        <Pricing />
        {/*
          <SecondaryFeatures />
          <Testimonials />
          */}
      </main>
      <CrispWithNoSSR />
      <Footer />
    </>
  );
}
